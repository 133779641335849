import classnames from 'classnames'
import backgroundImage from './images/text-banner-background.svg?url'
import type { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export default function TextBanner({ children }: Props) {
  return (
    <section
      className={classnames(
        'bg-brand-light-bg',
        'relative overflow-hidden px-6 py-10 text-left text-2xl font-black',
        'md:px-24 md:text-center',
        'lg:px-20 lg:py-10 lg:text-3xl',
        'xl:py-20',
        '2xl:rounded-lg 2xl:px-40 2xl:py-20'
      )}>
      <img src={backgroundImage} className="absolute inset-0 size-full object-cover" />
      <div className="relative z-10">{children}</div>
    </section>
  )
}
