import { MapPinIcon } from '@heroicons/react/24/outline'
import { useFetcher } from '@remix-run/react'
import classnames from 'classnames'
import { Button } from '~/components/design-system/button'
import { PADDING } from '~/components/landing-pages/constants'
import CircleIcon from '~/icons/circle-icon'
import type { action } from '~/routes/app.optimizer._index/route'
import SelectLocation from '~/routes/fullstack-components.select-location/route'
import backgroundImage from './images/search-banner-background.svg?url'

export default function SearchBanner() {
  const fetcher = useFetcher<typeof action>()
  const formErrors = fetcher.data && 'fieldErrors' in fetcher.data ? fetcher.data.fieldErrors : {}

  return (
    <section
      className={classnames(
        'relative flex w-full flex-col items-center justify-center gap-y-6 bg-gradient-to-tl from-[#EAFAF4] from-25% to-[#DFF8EF] to-85% py-10 text-center',
        'lg:py-20',
        '2xl:rounded-xl',
        PADDING
      )}>
      <img src={backgroundImage} className="absolute inset-0 size-full object-cover" />

      <h2 className="text-3xl font-black">Find better container routes</h2>

      <fetcher.Form
        method="POST"
        action="/app/optimizer?index"
        className={classnames('flex w-full flex-col items-center gap-3 text-left', 'md:flex-row', 'lg:w-2/3', 'xl:w-1/2')}
        autoComplete="off">
        <SelectLocation
          name="origin"
          placeholder="Origin"
          containerClassName="w-full"
          leadingIcon={CircleIcon}
          leadingIconProps={{ className: 'text-brand-medium size-5' }}
          inputClassName="py-3"
          data-invalid={!!formErrors.origin}
        />

        <SelectLocation
          name="destination"
          placeholder="Destination"
          containerClassName="w-full"
          leadingIcon={MapPinIcon}
          leadingIconProps={{ className: 'text-brand-medium size-5' }}
          inputClassName="py-3"
          data-invalid={!!formErrors.destination}
        />

        <Button type="submit" variant="primary" size="xl" className="w-full md:w-auto" loading={fetcher.state !== 'idle'}>
          Search
        </Button>
      </fetcher.Form>
    </section>
  )
}
