import { Marquee } from '@devnomic/marquee'
import { MapPinIcon } from '@heroicons/react/24/outline'
import { useFetcher } from '@remix-run/react'
import classnames from 'classnames'
import { useMemo } from 'react'
import { ClientOnly } from 'remix-utils/client-only'
import { useMediaQuery } from 'usehooks-ts'
import { Button } from '~/components/design-system/button'
import { PADDING } from '~/components/landing-pages/constants'
import CircleIcon from '~/icons/circle-icon'
import type { action } from '~/routes/app.optimizer._index/route'
import SelectLocation from '~/routes/fullstack-components.select-location/route'
import backgroundImage from './images/header-background.svg?url'
import type { ReactNode } from 'react'

type Props = {
  image: string | ReactNode
  carouselImages: string[]
  stopCarousel?: boolean
  children: ReactNode
}

export default function Header({ children, image, carouselImages, stopCarousel = false }: Props) {
  const fetcher = useFetcher<typeof action>()
  const formErrors = fetcher.data && 'fieldErrors' in fetcher.data ? fetcher.data.fieldErrors : {}

  const logos = useMemo(() => {
    return carouselImages.map((logo, index) => <img src={logo} key={index} alt={logo} className="mx-auto box-content h-[100px] w-auto" />)
  }, [carouselImages])

  const mq = useMediaQuery('(max-width: 1024px)')

  return (
    <div className={classnames('relative -mt-10 py-6', 'md:-mt-14 md:pt-6', 'lg:-mt-20 lg:pt-10', '2xl:-mt-20')}>
      <img src={backgroundImage} className="absolute inset-0 -z-10 size-full object-cover" />

      <header className={classnames('grid grid-cols-1 items-center gap-y-10 pb-10', 'xl:grid-cols-[2fr,3fr] xl:gap-x-10', '2xl:gap-x-20', PADDING)}>
        <div className="relative flex flex-col items-center gap-y-8">
          <div className={classnames('text-[32px]/tight font-black', 'md:text-center md:text-[48px]/tight', 'xl:text-left')}>{children}</div>

          <fetcher.Form
            method="POST"
            action="/app/optimizer?index"
            className={classnames('flex w-full flex-col items-center gap-3', 'md:flex-row', 'lg:w-2/3', 'xl:w-full')}
            autoComplete="off">
            <input type="hidden" name="event-name" value="optimizer-home-search" />

            <ClientOnly>{() => <input type="hidden" name="isMobile" value={mq.toString()} />}</ClientOnly>

            <SelectLocation
              name="origin"
              placeholder="Origin"
              containerClassName="w-full"
              leadingIcon={CircleIcon}
              leadingIconProps={{ className: 'text-brand-medium size-5' }}
              inputClassName="py-3"
              data-invalid={!!formErrors.origin}
            />

            <SelectLocation
              name="destination"
              placeholder="Destination"
              containerClassName="w-full"
              leadingIcon={MapPinIcon}
              leadingIconProps={{ className: 'text-brand-medium size-5' }}
              inputClassName="py-3"
              data-invalid={!!formErrors.destination}
            />

            <Button type="submit" variant="primary" size="xl" className="w-full md:w-auto" loading={fetcher.state !== 'idle'}>
              Search
            </Button>
          </fetcher.Form>
        </div>

        {typeof image === 'string' ? (
          <img
            src={image}
            alt="Header image"
            className={classnames('hidden', 'h-full w-full rounded-lg border border-brand-light object-cover shadow-strong md:block')}
          />
        ) : (
          image
        )}
      </header>

      <div className="-mb-10 overflow-hidden">
        {stopCarousel ? (
          <div className="flex flex-row items-center gap-[2rem] bg-white">{logos}</div>
        ) : (
          <Marquee fade pauseOnHover className="gap-[2rem] overflow-hidden [--duration:60s]" innerClassName="md:gap-[2rem] bg-white">
            {logos}
          </Marquee>
        )}
      </div>
    </div>
  )
}
